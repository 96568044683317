<template>
    <div class="row">
        <div class="col-lg-12">
            <hr>
            <h3 class="text-center text-uppercase">
                Note, kindly enter below the Paystack Reference Number on your transaction receipt sent to your email address
            </h3>
            <hr>
        </div>       

        <div class="col-md-3 text-center"></div>
        <div class="col-md-6 text-center">
            <base-input alternative=""
                label="Paystack Reference Number"
                placeholder="Enter Valid Paystack Reference Number"
                input-classes="form-control-alternative"
                type="text"
                v-model="reference"
            />
        </div>
        <div class="col-md-3 text-center"></div>

        <div class="col-lg-12">
            <hr>
            <base-button
                type="danger"
                @click="requeryPayment()"
            >
                <i class="fa fa-times"></i>
                Cancel Requery
            </base-button>

            
            <base-button
                type="success"
                class="right"
                @click="confirmMwanPayment()"
                :disabled="isloading"
            >
                <i class="fa fa-history"></i>
                {{ isloading ? 'Please Wait...' : 'Requery Now' }}
            </base-button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'RequeryMwanPayment',
        props: ['requeryPayment'],
        data() {
            return {
                isloading: false,
                reference: null
            }
        },
        computed: {
            ...mapGetters(['user']),
            email() {
                return this.user.email;
            }
        },
        methods: {
            ...mapActions(['fetchUserProfile', 'verifyMwanPayment']),
            confirmMwanPayment() {
                this.preloader();
                this.isloading = true;
                if(this.reference && this.email) {
                    let data = { reference : this.reference, email : this.email }; // 3TgTgCf9xf
                    this.verifyMwanPayment(data).then((res) => {// get records
                        this.isloading = false;
                        if(res.status) {
                            swal.fire('Payment Verified', res.msg, 'success');
                            this.fetchUserProfile();// reload page
                        } else {
                            swal.fire('Payment Failed', res.msg, 'error');
                        }
                    }).
                    catch(() => {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    swal.fire('Empty Field(s)!!', 'Enter valid Reference Number', 'warning');
                }
                



            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.fetchUserProfile();
        }
    }
</script>
<style scoped>
    .right {
        float: right !important;
    }
</style>