import axios from 'axios';

const state = {
    
};

const getters = {
    
};

const actions = {
    async updateBioData(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateBioDataUrl; 
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async updateSpecialization(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateSpecializationUrl; 
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    }
};

const mutations = {
    
};

export default {
    state,
    getters,
    actions,
    mutations
}