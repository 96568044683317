import axios from 'axios'; 

const state = {
    
};

const getters = { 
    
}; 

const actions = {
    async getUserAddQual(state) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserAddQualUrl;
        const id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;        
    },
    async addOldAdditionalQualification(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.addOldAdditionalQualificationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async deleteUserAq(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteUserAqUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    }
};

const mutations = {
    
};  

export default {
    state,
    getters,
    actions,
    mutations
}