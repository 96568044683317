import axios from 'axios';

const state = {
    postingDepartments: []
};

const getters = {
    postingDepartments: state => state.postingDepartments,
};

const actions = {
    async getUserAppointments(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAppointmentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async deleteUserAppointment(state, data) {// delete user Appointment
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteUserAppointmentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async saveUserAppointment(state, data) {// save user Appointment
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveUserAppointmentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async getAppointmentPostings(state, data) {// get user Appointments 
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAppointmentPostingsUrl;       
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async getPostingDepartments(state) {// get user Appointments  
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getPostingDepartmentsUrl;      
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {}, { headers: header});// make api call
        state.commit('setPostingDepartments', response.data);// set posting department function
        return response.data;        
    },
    async savePosting(state, data) {// save user Appointment
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.savePostingUrl; 
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async deletePosting(state, data) {// delete user Appointment
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deletePostingUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    }
};

const mutations = {
    setPostingDepartments: (state, departments) => (state.postingDepartments = departments),// set departments
};  

export default {
    state,
    getters,
    actions,
    mutations
}