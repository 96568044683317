import axios from 'axios';

const state = {

};

const getters = {
};

const actions = {
    async getRemitaPaymentStatus(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRemitaPaymentStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getRemitaOrderPaymentStatus(state, data) {// get remita order payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRemitaOrderPaymentStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async updateApplicationTransaction(state, data) {// update payment transaction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateApplicationTransactionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getUserPayments(state) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserPaymentsUrl;
        const id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async generatePaymentRRR(state, data) {// update payment transaction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.generatePaymentRRRUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async saveOtherPayments(state, data) {// update payment transaction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveOtherPaymentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getRemitaPaymentDetails(state, orderId) {// get remita order payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRemitaPaymentDetailsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {orderId}, { headers: header});// make api call
        return response.data;
    },
    async getDocStatusVerifyDetails(state) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getDocStatusVerifyDetailsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {}, { headers: header});// make api call
        return response.data;
    },
    async updateMwanTransaction(state, data) {// update payment transaction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateMwanTransactionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async verifyMwanPayment(state, data) {// update payment transaction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.verifyMwanPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}