import axios from 'axios';

const state = {

};

const getters = {

};

const actions = {
    async getSanctions(state, data) {// get Sanctions
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSanctionsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getSearchedSanctions(state, data) {// get searched Sanctions
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSearchedSanctionsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getPaginatedSanctions(state, url) {// get paginated Sanctions
        const authorizationToken = state.rootState.url.authorizationToken;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {},{ headers: header });// make api call
        return response.data;
    },
    async getExitRegister(state, data) {// get Sanctions
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getExitRegisterUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async sendComplaint(state, data) {// get Sanctions
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.sendComplaintUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async sendQuackReport(state, data) {// get Sanctions
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.sendQuackReportUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async sendExitRegisterForm(state, data) {// get Sanctions
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.sendExitRegisterFormUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}