import axios from 'axios';

const state = {

};

const getters = {

};

const actions = {
    async getBioData(state) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getBioDataUrl;
        const id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async getUserSpecialization(state) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserSpecializationUrl;
        const id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async getUserRegistrationDetails(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserRegistrationDetailsUrl;
        const id = state.rootState.user.userId;
        const type = data;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id, 'type' : type}, { headers: header});// make api call
        return response.data;
    },
    async createNewUserApplication(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.createNewUserApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async saveNewApplicationTransaction(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveNewApplicationTransactionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async submitApplication(state) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.submitApplicationUrl;
        const id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async updateInternshipCountry(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateInternshipCountryUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async createTempDocSignup(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.createTempDocSignupUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async updateTempAssessment(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateTempAssessmentUrl;
        data.id = state.rootState.user.userDataId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}