import axios from 'axios';

const state = {
    uploadProgress: null,
};

const getters = {
    
};

const actions = {
    async getRemediationBatch(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRemediationBatchUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;        
    },
    async getRemediationPayment(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRemediationPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;        
    },
    async getRemediationRrrStatus(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRemediationRrrStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;        
    },
    async getRemediationRemitaPaymentDetails(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRemediationRemitaPaymentDetailsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;        
    },
    async getPendingRemediationApplication(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getPendingRemediationApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;        
    },
    async updateRemediationStudentData(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateRemediationStudentDataUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;        
    },
    async uploadRemediationStudentPhoto(state, data) {// upload user documents
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadRemediationStudentPhotoUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.state.uploadProgress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    async uploadRemediationStudentDocuments(state, data) {// upload user documents
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadRemediationStudentDocumentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.state.uploadProgress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    async submitRemediationApplication(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.submitRemediationApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;        
    }
};

const mutations = {
    
};  

export default {
    state,
    getters,
    actions,
    mutations
}