import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'dashboard',
      component: DashboardLayout,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue')
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        },
        {
          path: '/update-profile',
          name: 'Update Profile',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile/UpdateProfile/UpdateProfile.vue')
        },
        {
          path: '/professional-data',
          name: 'Professional Data',
          component: () => import(/* webpackChunkName: "demo" */ './views/ProfessionalData.vue')
        },
        {
          path: '/notifications',
          name: 'Notifications',
          component: () => import(/* webpackChunkName: "demo" */ './views/Notifications/Notifications.vue')
        },
        {
          path: '/notifications/:slug',
          name: 'Notification Details',
          component: () => import(/* webpackChunkName: "demo" */ './views/Notifications/SingleNotification.vue')
        },
        {
          path: '/change-password',
          name: 'Change Password',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile/ChangePassword.vue')
        },
        // {
        //   path: '/icons',
        //   name: 'icons',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Icons.vue')
        // },
        {
          path: '/registration-guidelines',
          name: 'Registration Guidelines',
          component: () => import(/* webpackChunkName: "demo" */ './views/Others/PortalGuidelines.vue')
        },
        {
          path: '/appointment-posting/:id',
          name: 'Appointment Posting',
          component: () => import(/* webpackChunkName: "demo" */'./views/ProfessionalData/Appointment/Posting.vue')
        },
        {
          path: '/insurance-indemnity',
          name: 'Insurance Indemnity',
          component: () => import(/* webpackChunkName: "demo" */ './views/InsuranceIndemnity/InsuranceIndemnity.vue')
        },
        {
          path: '/purchase-insurance/:trans_no',
          name: 'Purchase Insurance',
          component: () => import(/* webpackChunkName: "demo" */'./views/InsuranceIndemnity/PurchaseInsurance.vue')
        },
        {
          path: '/applications',
          name: 'My Applications',
          component: () => import(/* webpackChunkName: "demo" */ './views/Application/Applications.vue')
        },
        {
          path: '/application-details/:id',
          name: 'Application Details',
          component: () => import(/* webpackChunkName: "demo" */ './views/Application/ApplicationDetails.vue')
        },
        {
          path: '/new-application/:code',
          name: 'New Application',
          component: () => import(/* webpackChunkName: "demo" */ './views/Application/NewApplications/Apply.vue')
        },
        {
          path: '/new-application-payment/:id',
          name: 'New Application Payment',
          component: () => import(/* webpackChunkName: "demo" */ './views/Application/Payments/Payment.vue')
        },
        {
          path: '/application-payment/:id',
          name: 'Application Payment',
          component: () => import(/* webpackChunkName: "demo" */ './views/Application/Payments/ApplicationPayment.vue')
        },
        {
          path: '/application-payment-details/:code',
          name: 'Application Payment Details',
          component: () => import(/* webpackChunkName: "demo" */ './views/Application/Payments/PaymentDetails/PaymentDetails.vue')
        },
        // {
        //   path: '/pending-application-payment/:id',
        //   name: 'Pending Application Payment',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Application/Payments/Payment.vue')
        // },
        {
          path: '/renew-temp/:code',
          name: 'Temporary License Renewal Payment',
          component: () => import(/* webpackChunkName: "demo" */ './views/Application/TempRenewal/TempRenewal.vue')
        },
        {
          path: '/pending-application/:id',
          name: 'Pending Application',
          component: () => import(/* webpackChunkName: "demo" */ './views/Application/PendingApplications/PendingApplication.vue')
        },
        {
          path: '/my-license',
          name: 'My License',
          component: () => import(/* webpackChunkName: "demo" */ './views/Application/License/License.vue')
        },
        {
          path: '/other-payments',
          name: 'Other Payments',
          component: () => import(/* webpackChunkName: "demo" */ './views/Others/OtherPayments.vue')
        },
        {
          path: '/new-account',
          name: 'New Account',
          component: () => import(/* webpackChunkName: "demo" */ './views/NewAccount/NewAccount.vue')
        },
        {
          path: '/new-temp-account',
          name: 'New Temporary Account',
          component: () => import(/* webpackChunkName: "demo" */ './views/NewTempAccount/NewTempAccount.vue')
        },
        {
          path: '/new-account/purchase-insurance/:trans_no',
          name: 'New Account Purchase Insurance',
          component: () => import(/* webpackChunkName: "demo" */ './views/NewAccount/PurchaseInsurance.vue')
        },
        {
          path: '/payments',
          name: 'My Payments',
          component: () => import(/* webpackChunkName: "demo" */ './views/Payments/Payments.vue')
        },
        {
          path: '/induction-tickets',
          name: 'My Induction Tickets',
          component: () => import(/* webpackChunkName: "demo" */ './views/InductionTickets/InductionTickets.vue')
        },
        {
          path: '/certificates',
          name: 'My Certificates',
          component: () => import(/* webpackChunkName: "demo" */ './views/Certificates/Certificates.vue')
        },
        {
          path: '/logout',
          name: 'logout',
          component: () => import(/* webpackChunkName: "demo" */ './views/Logout.vue')
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
        },
        {
          path: '/doctor-login',
          name: 'Doctor Login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Auth/Login/DoctorLogin.vue')
        },
        {
          path: '/doctor-register',
          name: 'New Doctor Account',
          component: () => import(/* webpackChunkName: "demo" */ './views/Auth/Register/DoctorRegister.vue')
        },
        {
          path: '/register',
          name: 'register',
          component: () => import(/* webpackChunkName: "demo" */ './views/Register.vue')
        },
        {
          path: '/password-reset',
          name: 'password reset',
          component: () => import(/* webpackChunkName: "demo" */ './views/PasswordReset.vue')
        },
        {
          path: '/update-password',
          name: 'update password',
          component: () => import(/* webpackChunkName: "demo" */ './views/UpdatePassword.vue')
        },
      ],
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/sanctions',
      name: 'Sanctioned Doctors',
      component: () => import(/* webpackChunkName: "demo" */ './views/Sanctions/Sanctions.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/exit-register',
      name: 'Exit Register',
      component: () => import(/* webpackChunkName: "demo" */ './views/ExitRegister/ExitRegister.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/doctor-complaint',
      name: 'Complaint against a Doctor',
      component: () => import(/* webpackChunkName: "demo" */ './views/Discipline/Complaints/AddComplaints.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/report-quack',
      name: 'Report Quack Personnel, School, Hospital',
      component: () => import(/* webpackChunkName: "demo" */ './views/Discipline/ReportQuack/AddReportQuack.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/report-deceased',
      name: 'Report Deceased Doctor',
      component: () => import(/* webpackChunkName: "demo" */ './views/ExitRegister/AddExitRegister.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/confirm-doctor-status',
      name: 'Confirm Doctor Status',
      component: () => import(/* webpackChunkName: "demo" */ './views/DoctorStatus/DoctorStatus.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/assessment-exam',
      name: 'Assessment Exam',
      component: () => import(/* webpackChunkName: "demo" */ './views/AssessmentExam/AssessmentExam.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/assessment-generate',
      name: 'Assessment Exam Payment',
      component: () => import(/* webpackChunkName: "demo" */ './views/AssessmentExam/AssessmentGenerate.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/assessment-callback',
      name: 'Assessment Exam Payment Callback',
      component: () => import(/* webpackChunkName: "demo" */ './views/AssessmentExam/AssessmentPaymentCallback.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/additional/assessment/callback',
      name: 'Assessment Exam Additional Payment Callback',
      component: () => import(/* webpackChunkName: "demo" */ './views/AssessmentExam/AssessmentForm/IncompletePayment/AssessmentAdditionalPaymentCallback.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/assessment/check-payment',
      name: 'Assessment Check Payment',
      component: () => import(/* webpackChunkName: "demo" */ './views/AssessmentExam/AssessmentCheckPayment.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/assessment/continue-application',
      name: 'Assessment Exam Application',
      component: () => import(/* webpackChunkName: "demo" */ './views/AssessmentExam/AssessmentApplication.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/assessment/check/application/status',
      name: 'Assessment Exam Application Status',
      component: () => import(/* webpackChunkName: "demo" */ './views/AssessmentExam/AssessmentApplicationStatus.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/assessment/registration-application',
      name: 'Assessment Exam Late Application',
      component: () => import(/* webpackChunkName: "demo" */ './views/AssessmentExam/AssessmentRegistrationApp.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/assessment/registration-status',
      name: 'Assessment Registration Status',
      component: () => import(/* webpackChunkName: "demo" */ './views/AssessmentExam/AssessmentRegistrationStatus.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/assessment/registration-status/:rrr',
      name: 'Assessment Exam Registration Status',
      component: () => import(/* webpackChunkName: "demo" */ './views/AssessmentExam/AssessmentRegStatusCallback.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/assessment/previous-batch',
      name: 'Assessment Re-Application',
      component: () => import(/* webpackChunkName: "demo" */ './views/AssessmentExam/AssessmentReRegistration.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/assessment-register-callback',
      name: 'Assessment Exam Re-register Payment Callback',
      component: () => import(/* webpackChunkName: "demo" */ './views/AssessmentExam/AssessmentReRegistration/AssessmentReRegisterCallback.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/assessment/application-form',
      name: 'Assessment Application Form',
      component: () => import(/* webpackChunkName: "demo" */ './views/AssessmentExam/AssessmentForm/AssessmentForm.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/assessment/applicant-dashboard',
      name: 'Applicant Dashboard',
      component: () => import(/* webpackChunkName: "demo" */ './views/AssessmentExam/ApplicantDashboard.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/temp-doctor-register',
      name: 'New Temporary Doctor Account',
      component: () => import(/* webpackChunkName: "demo" */ './views/Auth/Register/TempDoctorRegister.vue')
    },
    {
      path: '/temp-verify-callback',
      name: 'Temporary Verify Payment Callback',
      component: () => import(/* webpackChunkName: "demo" */ './views/Auth/Register/TempDoctorRegister/TempVerifyPaymentCallback.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/temp-doctor-signup',
      name: 'Temporary Doctor Signup Form',
      component: () => import(/* webpackChunkName: "demo" */ './views/Auth/Register/TempDoctorRegister/TempDoctorSignup.vue')
    },
    {
      path: '/doctor-status-verify-callback',
      name: 'Doctor Status Payment Callback',
      component: () => import(/* webpackChunkName: "demo" */ './views/DoctorStatus/DocVerifyPaymentCallback.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/get-doctor-status',
      name: 'Doctor Status Verification Page',
      component: () => import(/* webpackChunkName: "demo" */ './views/DoctorStatus/DoctorVerifyPage.vue'),
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/remediation/:code',
      name: 'Remediation Programme',
      component: () => import(/* webpackChunkName: "demo" */ './views/Remediation/Remediation.vue'),
      meta: {
        requiresVisitor: true
      }
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let saved = localStorage.getItem('lock_time');// set access token
    let lockTime = localStorage.getItem('lock_time');// get lock time
    if(! lockTime) {// initiate profile lock function
      let hours = 3;
      localStorage.setItem('lock_time', new Date().getTime() + (hours * 60 * 60 * 1000));// set lock time
    }
    if (saved && (saved < new Date().getTime())) {//session time out
        localStorage.clear();
        // window.location.reload();
    }
    if (! store.getters.isLoggedIn) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.isLoggedIn) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router;
