import axios from 'axios';

const state = {
    uploadProgress: null,
};

const getters = {

};

const actions = {
    async getAssessmentTypes(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAssessmentTypesUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getAssessmentBatch(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAssessmentBatchUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getAssessmentPayment(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAssessmentPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async generateAssessmentRrr(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.generateAssessmentRrrUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getAssRemitaPaymentDetails(state, id) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAssRemitaPaymentDetailsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { 'id' : id }, { headers: header});// make api call
        return response.data;
    },
    async getAssessmentRrrStatus(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAssessmentRrrStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getApplicantInfo(state, id) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getApplicantInfoUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { 'id' : id }, { headers: header});// make api call
        return response.data;
    },
    async saveAssessmentApplicantData(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveAssessmentApplicantDataUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async uploadAssessmentPhoto(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadAssessmentPhotoUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async deleteAssessmentEduRecord(state, id) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteAssessmentEduRecordUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { 'id' : id }, { headers: header});// make api call
        return response.data;
    },
    async deleteAssessmentQualification(state, id) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteAssessmentQualificationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { 'id' : id }, { headers: header});// make api call
        return response.data;
    },
    async deleteAssessmentEmployment(state, id) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteAssessmentEmploymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { 'id' : id }, { headers: header});// make api call
        return response.data;
    },
    async deleteAssessmentExamination(state, id) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteAssessmentExaminationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { 'id' : id }, { headers: header});// make api call
        return response.data;
    },
    async deleteAssessmentInstitution(state, id) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteAssessmentInstitutionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { 'id' : id }, { headers: header});// make api call
        return response.data;
    },
    async deleteAssessmentReferee(state, id) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteAssessmentRefereeUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { 'id' : id }, { headers: header});// make api call
        return response.data;
    },
    async submitAssessmentForm(state, id) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.submitAssessmentFormUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { 'id' : id }, { headers: header});// make api call
        return response.data;
    },
    async addExtraAssessmentCharge(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.addExtraAssessmentChargeUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getAssessmentInstitutions(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAssessmentInstitutionsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async uploadAssessmentDocuments(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadAssessmentDocumentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.state.uploadProgress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    async addAdditionalAssessmentRrr(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.addAdditionalAssessmentRrrUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    }
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}