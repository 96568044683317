import Vuex from 'vuex';
import Vue from 'vue';
import user from './modules/user';
import userEduRecord from './modules/professionalData/eduRecord';
import appointment from './modules/professionalData/appointment';
import internship from './modules/professionalData/internship';
import insurance from './modules/insurance/insurance';
import application from './modules/application/application';
import updateProfile from './modules/updateProfile/updateProfile';
import parameter from './modules/parameter/parameter';
import payment from './modules/payment/payment';
import newAccount from './modules/newAccount/newAccount';
import addQualification from './modules/professionalData/addQualification';
import url from './modules/url/url';
import sanction from './modules/sanction/sanction';
import assessment from './modules/assessment/assessment';
import general from './modules/general/general';
import remediation from './modules/remediation/remediation';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    modules: {
        user,
        userEduRecord,
        appointment,
        internship,
        insurance,
        application,
        updateProfile,
        parameter,
        payment,
        newAccount,
        addQualification,
        url,
        sanction,
        assessment,
        general,
        remediation
    }
});

