import axios from 'axios';

const state = {
    
};

const getters = {
    
};

const actions = {
    async getUserInsuranceRecords(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getIndemnityUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async getUserValidInsurance(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getValidIndemnityUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async addUserInsurance(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.addIndemnityUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async getAnInsuranceRecord(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAnIndemnityUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async reQueryInsurance(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.reQueryInsuranceUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {'code' : data}, { headers: header});// make api call
        return response.data;        
    },
};

const mutations = {
    
};  

export default {
    state,
    getters,
    actions,
    mutations
}