<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div class="pl-lg-4">
                            
                            <MwanPayment 
                                :loadApplication="loadApplication"        
                            />

                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import MwanPayment from '../NewAccount/MwanPayment.vue';

    export default {
        components: {
            MwanPayment
        },
        name: 'MwanPaymentTemplate',
        props: ['loadApplication']
    };
</script>
<style scoped>
    .width600 {
        width: 600px !important;
    }
    .marginLeft23 {
        margin-left: 23px;
    }
    .image-size {
        height: 180px;
        width: 190px;
    }
</style>
