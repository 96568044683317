<template>
    <div class="container">
            <div class="row" v-if="! showRequeryPage">
                <div class="col-lg-12" v-if="applicationType">
                    <hr>
                    <ul class="list-unstyled">
                        <li>
                            <h3 class="text-center text-uppercase">
                                Note that you have to pay the 
                                <em class="text-danger">
                                    Medical Women's Association Of Nigeria (MWAN) Levy
                                </em> 
                                before you can proceed...
                            </h3>
                            <hr class="my-4">
                            <h3 class="text-center">
                                Application Name: &nbsp;
                                <em class="text-capitalize font-weight-light text-danger">
                                    {{ applicationType.name }}
                                </em>
                            </h3>
                            <br>
                            <h3 class="text-center">
                                Total Amount: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ paystackAmount | formatPrice }}
                                </em>
                            </h3>

                            <div v-if="applicationType && applicationType.has_payment && applicationType.has_payment.has_breakdowns
                                        && applicationType.has_payment.has_breakdowns.length > 0"
                            >
                                <hr class="my-4">
                                <h1 class="text-center">
                                    Payment Breakdowns
                                </h1>
                                <hr class="my-4">
                                <h3
                                    v-for="breakdown in applicationType.has_payment.has_breakdowns"
                                    :key="breakdown.id"
                                >
                                    {{ breakdown.name }}: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ breakdown.amount | formatPrice }}
                                        </em>
                                    <br>
                                </h3>

                                <h3 v-if="paystackCharges">
                                    ICT Charges: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ paystackCharges | formatPrice }}
                                        </em>
                                    <br>
                                </h3>

                                <hr class="my-4">
                                <h3>
                                    Total: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ paystackAmount | formatPrice }}
                                    </em>
                                </h3>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-12">
                    <hr class="my-4">
                    <h3 class="text-uppercase text-warning">If your payment was successful and you were debited, click on the 
                        <em class="text-primary">Requery Payment</em> but if you just want to make the payment now, click on the 
                        <em class="text-info">Make Payment</em> button</h3>
                    <hr class="my-4">

                    <base-button
                        type="primary"
                        @click="requeryPayment()"
                    >
                        <i class="fa fa-history"></i>
                        Requery Payment
                    </base-button>

                    <Paystack
                        :amount="paystackInfo.amount"
                        :email="paystackInfo.email"
                        :firstname="paystackInfo.firstname"
                        :lastname="paystackInfo.lastname"
                        :phone="paystackInfo.phone"
                        :metadata="metadata"
                        :paystackkey="mwanPaystackKey"
                        :reference="reference"
                        :splitCode="mwanSplitCode"
                        :callback="callback"
                        :close="close"
                        :embed="false"
                        class="btn btn-info btn-lg right"
                    >
                        <i class="fa fa-credit-card"></i> 
                        {{ isloading ? 'Please Wait...' : 'Make Payment'}}
                    </Paystack>
                </div>
            </div>

            <RequeryMwanPayment
                :requeryPayment="requeryPayment"
                v-if="showRequeryPage"
            />
        </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Paystack from "vue-paystack";
    import swal from 'sweetalert2';// import sweet alert
    import RequeryMwanPayment from '../MwanPaymentTemplate/RequeryMwanPayment.vue';

    export default {
        name: 'MwanPayment',
        props: ['loadApplication'],
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        components: {
            Paystack,
            RequeryMwanPayment
        },
        data() {
            return {
                isloading: false,
                showRequeryPage: false
            }
        },
        computed: {
            ...mapGetters(['user', 'mwanPaystackKey', 'mwanSplitCode']),
            applicationType() {// if doctor is medical women and has not paid levy
                const mwan = this.user && this.user.basic_profile && this.user.basic_profile.mwan_levy ? this.user.basic_profile.mwan_levy : false;
                return mwan ? mwan.type : {};
            },
            totalAmount() {
                return this.applicationType && this.applicationType.has_payment ? this.applicationType.has_payment.amount : 7400;
            },
            paystackInfo() {
                return { 
                    amount: (this.paystackAmount * 100), 
                    email: this.user.email, 
                    firstname: (this.profile && this.profile.first_name ? this.profile.first_name : ''),
                    lastname: (this.profile && this.profile.last_name ? this.profile.last_name : ''),
                    phone: (this.profile && this.profile.phone ? this.profile.phone : ''),
                }
            },
            metadata() { // insert doctor folio number
                return { folio_number : (this.profile && this.profile.folio_number ? this.profile.folio_number : '')}
            },
            paystackAmount() {// calculate price plus charges
                const price = parseInt(this.totalAmount);
                const flatFee = 100;
                const decimalFee = 0.015;
                const totalAmount = ((price + flatFee) / (1 - decimalFee)) + 0.01
                return totalAmount.toFixed(2);
            },
            paystackCharges() {// get paystack charges 
                return (this.paystackAmount - parseInt(this.totalAmount)).toFixed(2);
            },
            profile() {
                return this.user && this.user.basic_profile ? this.user.basic_profile : {};
            },
            reference(){
                let text = "";
                let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";        
                for( let i=0; i < 10; i++ )
                text += possible.charAt(Math.floor(Math.random() * possible.length));        
                return text;
            }
        },
        methods: {
            ...mapActions(['fetchUserProfile', 'updateMwanTransaction']),
            callback(response){
                if(response.status == "success") {
                    let data = { paystack : response, id : this.profile.id };
                    this.preloader();// show loading
                    this.updateMwanTransaction(data).then((res) => {
                        if(res.status) {
                            swal.fire('Payment Updated Successfully', res.msg, 'success');
                            this.fetchUserProfile();
                            this.loadApplication();
                        } else {
                            swal.fire('Error Occurred!', res.msg, 'warning');
                            this.loadApplication();// load application
                        }
                    }).
                    catch(() => {
                        swal.fire('Error Occurred!', 'An error occured while updating payment, contact MWAN', 'warning');
                        this.loadApplication();// load application
                    });
                } else {
                    swal.fire('Error Occurred!', 'Payment failed, please try again...', 'warning');
                }                
            },
            close: function(){
                console.log("Payment closed")
            },
            requeryPayment() {
                this.showRequeryPage = ! this.showRequeryPage;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.fetchUserProfile();
        }
    }
</script>
<style scoped>
    .right {
        float: right !important;
    }
</style>