import axios from 'axios';

const state = {
    
};

const getters = {

};

const actions = {
    async getUserEducationalRecords(state, data) {// get user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getEducationalRecordsUrl; 
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async getInstitutions(state) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getInstitutionsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url,{}, { headers: header });// make api call
        return response.data;
    },
    async saveUserEduRecords(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveEduRecordsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async deleteUserEduRecord(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteUserEduRecordUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    }
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}