import axios from 'axios';

const state = {
    uploadDocProgress: null    
};

const getters = {
    
};

const actions = {
    async getUserInternshipRecords(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getInternshipUrl; 
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async uploadInternshipDocuments(state, data) {// upload user documents 
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadInternshipDocumentsUrl; 
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { 
            headers: header,
            onUploadProgress: uploadEvent => {
                state.state.uploadDocProgress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    async updateInternship(state, data) {// save user Appointment
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateInternshipUrl; 
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
};

const mutations = {
    
};  

export default {
    state,
    getters,
    actions,
    mutations
}